import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function DewanKomisaris() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const komisarisData = [
        {
            nama: 'Teni Agustini',
            jabatan: 'Komisaris Utama',
            lokasi: 'Jakarta',
            deskripsi: 'Teni Agustini memiliki lebih dari 30 tahun pengalaman di industri telekomunikasi, termasuk 15 tahun di posisi eksekutif. Beliau merupakan lulusan Magister Manajemen angkatan 1994 dan salah satu anggota tim pendiri Indihome. Sebagai Komisaris Utama PT Len Telekomunikasi Indonesia, Teni berperan strategis dalam menjaga kedaulatan dan kesejahteraan masyarakat melalui proyek Palapa Ring Paket Tengah.',
            gambar: 'https://via.placeholder.com/150',
        },
        {
            nama: 'Prasetyo Suharto',
            jabatan: 'Komisaris',
            lokasi: 'Bekasi',
            deskripsi: 'Prasetyo Suharto adalah Sarjana Hukum angkatan 1985 dengan pengalaman lebih dari 30 tahun di bidang Sumber Daya Manusia & Umum serta Operasional. Sebagai Komisaris PT Len Telekomunikasi Indonesia, beliau memiliki peran strategis dalam mengelola aspek SDM dan operasional perusahaan, penting untuk kesuksesan dan stabilitas perusahaan.',
            gambar: 'https://via.placeholder.com/150',
        },
    ];

    return (
        <div className="container mx-auto mt-20 px-4 pt-16">
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-down">
                    Dewan Komisaris
                </h1>
            </div>

            {komisarisData.map((komisaris, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg p-8 mb-10"
                    data-aos="fade-up"
                >
                    <img
                        src={komisaris.gambar}
                        alt={komisaris.nama}
                        className="w-40 h-40 md:w-52 md:h-52 object-cover rounded-lg mb-6 md:mb-0 md:mr-8"
                        effect="blur"
                    />
                    <div className="flex flex-col text-center md:text-left">
                        <h2 className="text-2xl font-semibold">{komisaris.nama}</h2>
                        <p className="text-lg text-red-700 mb-3">{komisaris.jabatan}</p>
                        <hr className='mb-3' />
                        <p className="text-md text-gray-600 text-justify">{komisaris.deskripsi}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DewanKomisaris;
