import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function ArticleDetail() {
    const { id } = useParams();  // Mengambil ID dari URL
    const [article, setArticle] = useState(null);

    useEffect(() => {
        // Fetch data artikel berdasarkan ID
        fetch(`https://strapis.len-telko.co.id/api/articles/${id}?populate=*`)
            .then(response => response.json())
            .then(data => setArticle(data.data))
            .catch(error => console.error('Error fetching article:', error));
    }, [id]);

    if (!article) {
        return <div>GAGAL MENGAMBIL DATA</div>;
    }

    return (
        <>
            <div className="container mx-auto px-4 py-16">
                <h1 className="text-4xl font-bold mb-4">{article.attributes.Title}</h1>
                <LazyLoadImage
                    src={`https://strapis.len-telko.co.id${article.attributes.Image?.data[0]?.attributes?.formats?.large?.url || article.attributes.Image?.data[0]?.attributes?.url}`}
                    alt={article.attributes.Title}
                    className="mb-8 w-full h-auto"
                    effect="blur"
                />
                <p>{article.attributes.Content}</p>
            </div>
        </>
    );
}

export default ArticleDetail;
