import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageHeader from './PageHeader';

function ArticleDetail() {
    const { id } = useParams();  // Mengambil ID dari URL
    const [article, setArticle] = useState(null);

    useEffect(() => {
        // Fetch article data by ID
        fetch(`https://strapis.len-telko.co.id/api/articles/${id}?populate=*`)
            .then(response => response.json())
            .then(data => setArticle(data.data))
            .catch(error => console.error('Error fetching article:', error));
    }, [id]);

    if (!article) {
        return <div>Loading...</div>;  // Menampilkan loading jika data belum siap
    }

    const { Title, Image, DataContent } = article.attributes;

    // Fungsi untuk merender blok Rich Text dengan handling bold, italic, dll.
    const renderContent = (DataContent) => {
        return DataContent.map((block, index) => {
            if (block.type === 'paragraph') {
                return (
                    <p key={index} className="text-justify text-gray-700 leading-loose text-lg mb-4">
                        {block.children.map((child, childIndex) => {
                            if (child.type === 'text') {
                                let textElement = child.text;

                                // Apply bold if the text is bold
                                if (child.bold) {
                                    textElement = <strong key={childIndex}>{textElement}</strong>;
                                }

                                // Apply italic if the text is italic
                                if (child.italic) {
                                    textElement = <em key={childIndex}>{textElement}</em>;
                                }

                                return textElement;  // Return text node
                            }
                            return null;  // Handle other types if necessary
                        })}
                    </p>
                );
            }
            return null; // If there are other block types, handle them here
        });
    };

    return (
        <>
            <PageHeader title="Artikel / Berita" />
            <div className="container mx-auto px-4 pt-20">
                {/* Title */}
                <h1 className="text-2xl leading-loose text-center font-extrabold text-black my-6">{Title}</h1> {/* Perbesar ukuran judul */}

                {/* Image */}
                {Image?.data?.[0]?.attributes?.url ? (
                    <img
                        src={`https://strapis.len-telko.co.id${Image.data[0].attributes.url}`}
                        alt={Title}
                        className="w-full object-cover mb-8"
                    />
                ) : null}

                {/* Render the DataContent Rich Text */}
                <div className="text-2x text-gray-700 text-justify leading-relaxed"> {/* Perbesar teks konten */}
                    {DataContent ? renderContent(DataContent) : "No content available."}
                </div>

                {/* Published Date and Author */}
                <div className="mt-8 text-sm text-gray-500 flex justify-between">
                    {/* <span>{PublishedDate ? `Published on: ${PublishedDate}` : null}</span> */}
                    {/* <span>{Author ? `Author: ${Author}` : null}</span> */}
                </div>
            </div>
        </>
    );
}

export default ArticleDetail;
