import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [navbarScrolled, setNavbarScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setNavbarScrolled(true);
            } else {
                setNavbarScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                const headerHeight = document.querySelector('.page-header')?.offsetHeight || 0;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - headerHeight - 80;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    const navItems = [
        { name: 'Beranda', path: '/#id-carousel' },
        {
            name: 'Tentang Kami',
            dropdown: [
                { name: 'Profil Perusahaan', path: '/tentang-kami#profil-perusahaan' },
                { name: 'Visi & Misi', path: '/tentang-kami#visi-misi' },
                { name: 'Sejarah Perusahaan', path: '/tentang-kami#sejarah' },
                { name: 'Struktur Organisasi', path: '/tentang-kami#struktur-organisasi' },
            ],
        },
        {
            name: 'Manajemen',
            dropdown: [
                { name: 'Kepemilikan Saham', path: '/manajemen#kepemilikan-saham' },
                { name: 'Dewan Komisaris', path: '/manajemen#dewan-komisaris' },
                { name: 'Direksi', path: '/manajemen#direksi' },
            ],
        },
        {
            name: 'Palapa Ring',
            dropdown: [
                { name: 'Proyek Strategis Nasional', path: '/palapa-ring#proyek-strategis-nasional' },
                { name: 'Palapa Ring Tengah', path: '/palapa-ring#palapa-ring-tengah' },
                { name: 'Lokasi dan Wilayah', path: '/palapa-ring#lokasi-dan-wilayah' },
            ],
        },
        // {
        //     name: 'Berita',
        //     dropdown: [
        //         { name: 'Press Release', path: '#press-release' },
        //         { name: 'Berita', path: '#berita-biasa' },
        //     ],
        // },
        { name: 'Kontak Kami', path: '/kontak-kami' },
    ];

    return (
        <>
            <nav
                className={`fixed top-0 w-full z-50 px-5 h-20 transition-colors duration-500 ease-in-out ${navbarScrolled ? 'bg-gradient-to-b from-black to-[#111827] text-white' : 'bg-gradient-to-b from-black to-[#111827] text-white'
                    }`}
            >
                <div className="mx-auto flex items-center justify-between h-full px-2">
                    <div className="flex items-center">
                        <Link to="/" className="hover:text-gray-300">
                            <LazyLoadImage alt="LOGO LTI" className="h-12" src={`${process.env.PUBLIC_URL}/assets-web/LTI_Full_Logo11.png`} effect="blur" />
                        </Link>
                    </div>

                    <div className="lg:hidden">
                        <button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="text-white hover:text-gray-300 focus:outline-none"
                        >
                            {isMenuOpen ? (
                                <i className="fa-solid fa-xmark w-6 h-6"></i> // Close icon
                            ) : (
                                <i className="fa-solid fa-bars w-6 h-6"></i> // Menu (bars) icon
                            )}
                        </button>
                    </div>

                    {/* Menu Desktop */}
                    <ul className="hidden lg:flex space-x-6">
                        {navItems.map((item, index) => (
                            <li key={index} className="relative shadow-xl shadow-inner"
                                onMouseEnter={() => setIsDropdownOpen(item.name)}
                                onMouseLeave={() => setIsDropdownOpen(null)}
                            >
                                {!item.dropdown ? (
                                    <Link to={item.path} className="hover:text-blue-500 text-sm">{item.name}</Link>
                                ) : (
                                    <>
                                        <span className={`hover:text-blue-500 text-sm cursor-pointer ${isDropdownOpen === item.name ? 'text-blue-500' : ''}`}>
                                            {item.name}
                                            <i className="fa-solid fa-caret-down ml-1"></i>
                                        </span>
                                        {isDropdownOpen === item.name && (
                                            <div className="sub-dropdown-nav absolute left-0 top-full mt-0 bg-white text-black shadow-2xl shadow-inner rounded-2xl z-10">
                                                <ul className="py-2 whitespace-nowrap">
                                                    {item.dropdown.map((dropdownItem, idx) => (
                                                        <li key={idx} className="hover:bg-gray-200 hover:text-blue-500 transition-colors duration-300 px-4 py-2 rounded-md">
                                                            <Link to={dropdownItem.path} className="block px-4 py-2 text-sm">{dropdownItem.name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Menu Mobile */}
                {isMenuOpen && (
                    <div
                        className={`p-10 bg-gradient-to-b from-[#111827] to-black w-full text-white h-screen shadow-lg fixed top-20 right-0 z-50 transition-transform duration-500 ease-out transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    >
                        <ul className="flex flex-col items-start space-y-6 py-8 pl-8">
                            {navItems.map((item, index) => (
                                <li key={index} className="w-full">
                                    {!item.dropdown ? (
                                        <Link
                                            to={item.path}
                                            className="text-white hover:text-blue-400 text-sm block pb-2"
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            {item.name}
                                        </Link>
                                    ) : (
                                        <>
                                            <div
                                                onClick={() => setIsDropdownOpen(isDropdownOpen === item.name ? null : item.name)}
                                                className="text-white hover:text-blue-400 text-sm cursor-pointer flex items-center w-full pb-2 pr-4"
                                            >
                                                {item.name}
                                                <i
                                                    className={`fa-solid fa-caret-down ml-2 transition-transform duration-300 ${isDropdownOpen === item.name ? 'rotate-180' : ''}`}
                                                ></i>
                                            </div>
                                            {isDropdownOpen === item.name && (
                                                <ul className="m-6 pl-6">
                                                    {item.dropdown.map((dropdownItem, idx) => (
                                                        <li key={idx} className="pb-2">
                                                            <Link
                                                                to={dropdownItem.path}
                                                                className="text-white hover:text-blue-400 text-sm block py-3"
                                                                onClick={() => setIsMenuOpen(false)}
                                                            >
                                                                {dropdownItem.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {/* Social Media Section */}
                        <div className='text-center'>
                            <h5 className="text-md font-bold mb-4 border-b-2 border-white inline-block">
                                Social Media
                            </h5>
                            <div className="space-x-4">
                                <a href="https://www.facebook.com/len.telko/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f text-lg hover:text-gray-300 transition"></i>
                                </a>
                                <a href="https://www.instagram.com/len.telko/?hl=en" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram text-lg hover:text-gray-300 transition"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/len-telko/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in text-lg hover:text-gray-300 transition"></i>
                                </a>
                            </div>
                        </div>

                        {/* Copyright Section */}
                        <div className="border-t border-white mt-8 pt-4 text-center text-sm">
                            <span>Copyright &copy; 2024 PT Len Telekomunikasi Indonesia. <br />All Rights Reserved.</span>
                        </div>

                        {/* Logo di bawah */}
                        {/* <div className="absolute bottom-30 left-1/2 transform -translate-x-1/2">
                            <LazyLoadImage alt="LOGO LTI" className="h-12" src={`${process.env.PUBLIC_URL}/assets-web/LTI_Full_Logo11.png`} effect="blur" />
                        </div> */}
                    </div>
                )}
            </nav>
        </>
    );
}

export default Navbar;
