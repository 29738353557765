import React, { useEffect, useState } from 'react';

function ArticlesCard({ title, description, imageSrc, iconSrc, slug, PublishedDate }) {
    // Function to trim description to a specific length
    const truncateString = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    return (
        <>
            <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 relative">
                {/* Image Section */}
                <img
                    src={imageSrc}
                    alt={title}
                    className="w-full h-48 object-cover w-full"
                />
                <div className="relative">
                </div>

                {/* Text Section */}
                <div className="p-6">
                    <a href={`/articles/${slug}`} className="">
                        <h2 className="text font-bold text-blue-700 mb-4">{truncateString(title, 50)}</h2>
                        <p className="text-sm text-gray-700 mb-4 text-justify">{truncateString(description, 200)}</p>
                        {/* Flex container to align the button and the date */}
                        <div className="flex justify-between items-center mt-4">
                            <button
                                className='text-white bg-red-600 text-sm p-2 px-4 rounded-full hover:bg-red-700 transition-colors'
                            >
                                Selengkapnya
                            </button>
                            <div className="text-sm text-gray-500">
                                {PublishedDate}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}

function NewsSection() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        // Fetch data from Strapi API with populated image data
        fetch('https://strapis.len-telko.co.id/api/articles?populate=*')
            .then(response => response.json())
            .then(data => {
                // Sorting articles based on PublishedDate in descending order
                const sortedArticles = data.data
                    .sort((a, b) => new Date(b.attributes.PublishedDate) - new Date(a.attributes.PublishedDate))
                    .slice(0, 4); // Limit to the last 3 articles

                setArticles(sortedArticles);
            })
            .catch(error => console.error('Error fetching articles:', error));
    }, []);

    return (
        <div className="relative">
            {/* Background with Parallax Effect */}
            <div
                className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `url('assets-web/hero_banner_3.png')`,
                    backgroundAttachment: 'fixed',
                    height: '100%',
                    zIndex: -1
                }}
            ></div>

            {/* Content Section */}
            <div className="bg-gray-100 bg-opacity-80 py-16 relative z-10">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {articles.map((article) => (
                            <ArticlesCard
                                data-aos="flip-left"
                                key={article.id}
                                title={article.attributes.Title}  // Title from API
                                description={article.attributes.Description}  // Content from API
                                imageSrc={`https://strapis.len-telko.co.id${article.attributes.Image?.data[0]?.attributes?.formats?.medium?.url || article.attributes.Image?.data[0]?.attributes?.url}`}  // Full URL for the image
                                iconSrc="https://via.placeholder.com/64x64.png?text=Icon"
                                slug={article.id}  // Assuming 'slug' is provided in the API
                                PublishedDate={new Date(article.attributes.PublishedDate).toLocaleDateString('id-ID', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric'
                                }).toString()}  // Ensure it's passed as a string
                            />
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSection;
