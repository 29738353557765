import React from 'react';  // Pastikan useEffect di-import

function StrukturOrganisasi() {
    return (
        <>
            <h1 className="text-2xl font-bold">Struktur Organisasi</h1>
            <p>Isi dari Struktur Organisasi...</p>
        </>
    );
}

export default StrukturOrganisasi;
