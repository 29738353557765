import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Direksi() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const direksiData = [
        {
            nama: 'Ganda Yudha Asparaini',
            jabatan: 'Plt. Direktur Utama',
            lokasi: 'Bandung',
            deskripsi: 'Ganda Yudha Asparaini merupakan Plt. Direktur Utama PT Len Telekomunikasi Indonesia. Beliau memiliki pengalaman lebih dari 25 tahun di BUMN dan memegang berbagai posisi strategis, termasuk penasihat senior di bidang Sumber Daya Manusia dan Umum. Ia juga aktif dalam pengelolaan proyek besar seperti Palapa Ring Paket Tengah.',
            gambar: 'https://via.placeholder.com/150',
        },
        {
            nama: 'Dirgantara Putra',
            jabatan: 'Direktur Bisnis dan Operasi',
            lokasi: 'Jakarta',
            deskripsi: 'Dirgantara Putra menjabat sebagai Direktur Bisnis dan Operasi PT Len Telekomunikasi Indonesia. Beliau memiliki pengalaman lebih dari 25 tahun di industri telekomunikasi, termasuk peran penting dalam pengembangan jaringan serat optik di proyek-proyek besar di Indonesia. Dirgantara bertanggung jawab atas pengelolaan operasional dan pengembangan bisnis perusahaan.',
            gambar: 'https://via.placeholder.com/150',
        },
        {
            nama: 'Priono Joni Hartanto',
            jabatan: 'Plt. Direktur Keuangan dan SDM',
            lokasi: 'Bandung',
            deskripsi: 'Priono Joni Hartanto adalah Plt. Direktur Keuangan dan SDM PT Len Telekomunikasi Indonesia. Beliau memiliki pengalaman 20 tahun di PT Len Industri dengan fokus pada manajemen rantai pasok dan pengembangan organisasi. Priono memiliki berbagai sertifikasi dan pelatihan yang berkontribusi pada kemajuan karirnya.',
            gambar: 'https://via.placeholder.com/150',
        },
    ];

    return (
        <div className="container mx-auto pt-16 px-4">
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-up">
                    Direksi
                </h1>
            </div>

            {direksiData.map((direksi, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg p-8 mb-10"
                    data-aos="fade-up"
                >
                    <img
                        src={direksi.gambar}
                        alt={direksi.nama}
                        className="w-40 h-40 md:w-52 md:h-52 object-cover rounded-lg mb-6 md:mb-0 md:mr-8"
                        effect="blur"
                    />
                    <div className="flex flex-col text-center md:text-left">
                        <h2 className="text-2xl font-semibold">{direksi.nama}</h2>
                        <p className="text-lg text-red-700 mb-3">{direksi.jabatan}</p>
                        <hr className='mb-3' />
                        <p className="text-md text-gray-600 text-justify">{direksi.deskripsi}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Direksi;
