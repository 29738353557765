import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import komponen lainnya
// import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import AboutMe from './components/AboutMe';
import ArticlesCard from './components/ArticlesCard';
import BannerFooter from './components/BannerFooter';
// import DekomDireksi from './components/DekomDireksi';
import PalapaRingTengah from './components/PalapaRingTengah';
import PenggunaLayanan from './components/PenggunaLayanan';
import KontakKami from './components/KontakKami/KontakKami';
// import SkemaBisnis from './components/SkemaBisnis';
import ArticleDetail from './components/ArticleDetail'; // Komponen untuk detail artikel
import ArticlesSection from './components/ArticlesSection';

import TentangKamiLayout from './components/TentangKami/TentangKamiLayout';
import ManajemenLayout from './components/Manajemen/ManajemenLayout';
import PalapaRingLayout from './components/PalapaRing/PalapaRingLayout';

function App() {
  const turnstileRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    // Cek apakah status verifikasi sudah ada di localStorage
    const storedVerification = localStorage.getItem('isVerified');
    if (storedVerification === 'true') {
      setIsVerified(true);
    } else {
      // Render Turnstile jika pengguna belum diverifikasi
      if (window.turnstile && turnstileRef.current) {
        window.turnstile.render(turnstileRef.current, {
          sitekey: "0x4AAAAAAAsfHjcLuIrJmLy3", // Gantilah dengan Site Key dari Cloudflare
          callback: (newToken) => {
            setIsVerified(true); // Set status terverifikasi ketika token diterima
            localStorage.setItem('isVerified', 'true'); // Simpan status verifikasi di localStorage
          },
          'error-callback': () => {
            setIsVerified(false); // Set status tidak terverifikasi jika ada error
          },
          'expired-callback': () => {
            setIsVerified(false); // Jika token kedaluwarsa, kembali ke status tidak terverifikasi
          },
        });
      }
    }
  }, []);

  return (
    <Router>
      <div>
        {!isVerified ? (
          // Render Turnstile jika belum terverifikasi
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              color: 'white',
              zIndex: 9999,
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <p className='py-10'>Please verify that you are human to access the website</p>
              <div
                ref={turnstileRef}
                className="cf-turnstile"
                style={{
                  width: '100%',
                  maxWidth: '400px', // Mengatur lebar maksimum
                  margin: '0 auto',
                }}
              ></div>
            </div>
          </div>
        ) : (
          // Render konten utama jika sudah terverifikasi
          <>
            {/* Navbar selalu tampil */}
            <Navbar />

            {/* Routes untuk mengelola konten utama */}
            <Routes>
              {/* Halaman Utama */}
              <Route path="/" element={
                <>
                  {/* Preloader hanya muncul saat halaman pertama kali dimuat */}
                  {/* <Preloader /> */}
                  <Carousel />
                  <AboutMe />
                  <ArticlesCard />
                  <PalapaRingTengah />
                  <PenggunaLayanan />
                  <BannerFooter />
                </>
              } />

              <Route path="/tentang-kami" element={<TentangKamiLayout />} />
              <Route path="/manajemen" element={<ManajemenLayout />} />
              <Route path="/palapa-ring" element={<PalapaRingLayout />} />

              {/* Halaman Lainnya */}
              <Route path="/articles" element={<ArticlesSection />} />
              {/* Halaman detail artikel */}
              <Route path="/articles/:id" element={<ArticleDetail />} />

              <Route path="/kontak-kami" element={<KontakKami />} />
            </Routes>

            {/* Footer selalu tampil */}
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
